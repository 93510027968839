import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { ImageProps, StaticImageData } from 'next/image';
import Image from 'next/image';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

interface BaseOpts {
  src?: ImageProps['src'];
}

interface ExtraOpts {
  alt: string;
  fallbackSrc: string;
}

type Listeners = Pick<ImageProps, 'onError' | 'onLoadingComplete'>;

export type NextImageProps = BaseOpts &
  Partial<ExtraOpts> &
  BoxProps & {
    imageProps?: Partial<ImageProps>;
    loadingSrc?: string | StaticImageData;
    isLoadingCover?: boolean;
  };

type PiperOpts = BaseOpts & Partial<ExtraOpts> & Listeners;

type PiperRes = BaseOpts &
  Pick<ExtraOpts, 'alt'> &
  Listeners & {
    isLoading: boolean;
  };

export const NextImage = forwardRef<HTMLDivElement, NextImageProps>(
  function NextImage(
    {
      src,
      alt,
      fallbackSrc,
      loadingSrc,
      isLoadingCover = true,
      imageProps: _imageProps = {},
      children,
      ...rest
    }: NextImageProps,
    ref,
  ) {
    const { style, ...imageProps } = _imageProps;
    const { isLoading, ...fallbackImageProps } = useFallbackImageProps({
      src,
      alt,
      fallbackSrc,
      ...rest,
      ...imageProps,
    });
    const isAutoHeight = !rest.height || rest.height === 'auto';

    return (
      <Box
        ref={ref}
        position="relative"
        minHeight={
          isLoading && !!loadingSrc && isAutoHeight ? '50px' : undefined
        }
        {...rest}
        css={{
          ...(isAutoHeight
            ? {
                /**
                 * equal rule as `:first-child`
                 * @see https://github.com/emotion-js/emotion/issues/1105
                 */
                '& > :not(*~*)': {
                  position: 'relative!important',
                  zIndex: 1,
                },
                '& > :not(*~*) img': {
                  ...style,
                  position: 'static!important',
                  width: '100%!important',
                  height: 'auto!important',
                  ...style,
                },
              }
            : {
                '& > :not(*~*) img': {
                  ...style,
                },
              }),
          ...rest.css,
        }}
      >
        {fallbackImageProps.src && (
          <Image
            unoptimized={!isURLAllowed(fallbackImageProps.src)}
            fill
            {...imageProps}
            {...fallbackImageProps}
            src={fallbackImageProps.src}
            alt={alt || fallbackImageProps.alt}
          />
        )}
        {isLoading && !!loadingSrc && (
          <Image
            src={loadingSrc}
            alt="loading"
            fill
            style={isLoadingCover ? { objectFit: 'cover' } : undefined}
            priority={true}
          />
        )}
        {children}
      </Box>
    );
  },
);

const isURLAllowed = (url: ImageProps['src']) => {
  if (typeof url !== 'string') {
    return true;
  }

  // relative url (dynamic importing)
  try {
    new URL(url);
  } catch (e) {
    return true;
  }

  // all third-party domains cannot be optimized by nextJS
  return false;
};

const useFallbackImageProps = ({
  src: _src,
  fallbackSrc,
  alt,
  onError,
  onLoadingComplete,
}: PiperOpts): PiperRes => {
  const [src, setSrc] = useState(_src);
  const [isLoading, setIsLoading] = useState(true);
  const currentSrcRef = useRef(src);

  useEffect(() => {
    setSrc(_src);
    setIsLoading(true);
  }, [_src]);

  const updateFallback = useCallback(() => {
    if (currentSrcRef.current !== src) return;
    if (!fallbackSrc) return;
    if (typeof src !== 'string') return;
    setSrc(fallbackSrc);
  }, [fallbackSrc, src]);

  return {
    src,
    // don't show alt broken icon when using fallbackSrc
    alt: src === fallbackSrc ? '' : alt ?? '',
    onError: useCallback<NonNullable<ImageProps['onError']>>(
      (e) => {
        onError?.(e);
        updateFallback();
      },
      [onError, updateFallback],
    ),
    /**
     * onError event will be lost on SSR sometimes
     * @see https://github.com/facebook/react/issues/15446
     */
    onLoadingComplete: useCallback<
      NonNullable<ImageProps['onLoadingComplete']>
    >(
      (image) => {
        setIsLoading(false);
        onLoadingComplete?.(image);

        const { naturalWidth, naturalHeight } = image;
        // if image intrinsic size is 0, it's very likely failed
        if (!naturalWidth && !naturalHeight) {
          updateFallback();
        }
      },
      [updateFallback, onLoadingComplete],
    ),
    isLoading,
  };
};
