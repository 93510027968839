import { Box } from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';

import { MotionBox } from '../../Libraries/motion';

interface UnderlineTextProps {
  lineColor?: string;
  lineGradient?: string;
  lineFrom?: 'left' | 'right';
  lineHeight?: string;
  linePadding?: string | number;
  isActive?: boolean;
}

export const UnderlineText = ({
  lineColor,
  lineGradient,
  lineHeight = '5px',
  linePadding = 1,
  isActive,
  children,
}: PropsWithChildren<UnderlineTextProps>) => (
  <Box position="relative" boxSize="100%">
    <Box px={linePadding}>{children}</Box>
    {isActive && (
      <MotionBox
        height={lineHeight}
        position="absolute"
        top="100%"
        initial={{ width: '0%' }}
        animate={{ width: '100%' }}
        transition="all 0.4s ease-out"
        bgColor={lineColor}
        bgGradient={lineGradient}
      />
    )}
  </Box>
);
