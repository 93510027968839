import type { IntlInternalUrl } from '@ts-intl/integration';
import { IntlUtilsBrowser } from '@ts-intl/integration';
import type { LinkProps as NextLinkProps } from 'next/link';
import NextLink from 'next/link';
import type { Router } from 'next/router';
import router, { useRouter } from 'next/router';
import { type ReactNode, useMemo } from 'react';
import { useMount } from 'react-use';

import { languageConfig } from './config';

// IMPORTANT!
// all of these api aim to hide language slug from url
// ENABLE: SSG or ./pages/[lang]
// DISABLE: SSR, should use next/i18n directly

const intlBrowser = new IntlUtilsBrowser(languageConfig);

export const useRouterWithI18n = () => {
  const router = useRouter();
  return useMemo(() => intlBrowser.enhanceRouter(router), [router]);
};

export const routerWithI18n = new Proxy<
  ReturnType<typeof intlBrowser.enhanceRouter>
>(router, {
  get: (defaultNextRouter, prop, receiver) => {
    const router = Reflect.get(defaultNextRouter, 'router', receiver);
    if (router && typeof prop === 'string' && prop in router) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return intlBrowser.enhanceRouter(router)[prop as keyof Router];
    }
    return Reflect.get(defaultNextRouter, prop, receiver);
  },
});

export const NextLinkWithIntl = (
  props: Omit<NextLinkProps, 'as' | 'href' | 'locale'> & {
    href: IntlInternalUrl;
    locale?: string;
    children?: ReactNode;
  },
) => {
  const { href, locale, ...restProps } = props;
  const router = useRouter();
  const url = useMemo(
    () => intlBrowser.revertRouterUrl(href, locale ?? router.locale),
    [href, locale, router.locale],
  );
  const pathname = typeof url === 'string' ? url : url.pathname;
  if (!pathname?.startsWith('/')) return <NextLink {...props} legacyBehavior />;
  return <NextLink href={url} {...restProps} legacyBehavior />;
};

export const useRedirectToUserLanguage = () => {
  useMount(() => {
    const userLanguage = getLanguageFromNavigator();
    if (
      !userLanguage ||
      [routerWithI18n.locale, intlBrowser.config.defaultLanguage].includes(
        userLanguage,
      )
    )
      return;
    routerWithI18n.replace(
      {
        pathname: routerWithI18n.pathname,
        query: routerWithI18n.query,
      },
      {
        locale: userLanguage,
      },
    );
  });
};

const getLanguageFromNavigator = () => {
  return typeof window !== 'undefined'
    ? intlBrowser.languages.find(
        (l) =>
          window.navigator.language.toLowerCase() === l.toLowerCase() ||
          window.navigator.language
            .toLowerCase()
            .startsWith(l.toLowerCase() + '-'),
      )
    : undefined;
};
