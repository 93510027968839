/* eslint-disable @ts-intl/ts-intl/no-dynamic-keys */

import type { useTranslations } from 'next-intl';

export const dynamicTranslateText = (
  t: ReturnType<typeof useTranslations<string>>,
  i18nKey: string,
): string => {
  return t(i18nKey);
};
