import type { LinkProps } from '@chakra-ui/react';
import { Link } from '@chakra-ui/react';
import { UnderlineText } from '@ui/components/Atoms/UnderlineText';
import { NextLinkWithIntl, useRouterWithI18n } from '@ui/locales/browserSSG';
import type { ReactNode } from 'react';
import React from 'react';

export type TopNavLinkProps = LinkProps & {
  href: string;
  children: ReactNode;
};

export const TopNavLink = ({ href, children, ...props }: TopNavLinkProps) => {
  const router = useRouterWithI18n();
  const isActive = (router.pathname || '/') === href;

  return (
    <NextLinkWithIntl
      passHref
      href={href}
      locale={router.locale}
      legacyBehavior
    >
      <Link
        textStyle={{ base: 'body2', lg: 'body1' }}
        textTransform="uppercase"
        color={isActive ? '#CB8AFF' : 'white'}
        fontWeight={isActive ? 'bold' : 'normal'}
        fontFamily="luckiestGuy"
        {...props}
      >
        <UnderlineText
          lineColor="#CB8AFF"
          lineHeight="4px"
          linePadding={0.5}
          isActive={isActive}
        >
          {children}
        </UnderlineText>
      </Link>
    </NextLinkWithIntl>
  );
};
