export const ellipseCenter = (
  word: string,
  options?: { head?: number; tail?: number },
) => {
  const { head = 4, tail = 4 } = options ?? {};
  if (word.length <= head + tail) return word;

  const pieces = [word.slice(0, head), word.slice(-tail)];
  return pieces.join('..');
};
