import { useBreakpointValue } from '@chakra-ui/react';
import { useCallback } from 'react';

type BreakpointFn = (bp: BreakPoint) => boolean;
type BreakPoint = 'base' | 'xxs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

function useBreakpointFn(bpFn: BreakpointFn): boolean;
function useBreakpointFn(bpFn: BreakpointFn[]): boolean[];
function useBreakpointFn(
  bpFn: BreakpointFn | BreakpointFn[],
): boolean | boolean[] {
  const breakpoint = useBreakpointValue<BreakPoint>({
    base: 'base',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
  });

  const handleBpFn = useCallback(
    (fn: BreakpointFn) => (breakpoint ? fn(breakpoint) : false),
    [breakpoint],
  );

  if (Array.isArray(bpFn)) return bpFn.map(handleBpFn);
  else return handleBpFn(bpFn);
}
export { useBreakpointFn };

// Breakpoint functions:
export const isBaseToXxsFn: BreakpointFn = (bp) => bp === 'base';
export const isXxsToSmFn: BreakpointFn = (bp) => bp === 'xxs';
export const isBaseToSmFn: BreakpointFn = (bp) =>
  isBaseToXxsFn(bp) || isXxsToSmFn(bp);
export const isSmToMdFn: BreakpointFn = (bp) => bp === 'sm';
export const isMdToLargeFn: BreakpointFn = (bp) => bp === 'md';
export const isLargeToXLargeFn: BreakpointFn = (bp) => bp === 'lg';
export const isXLargeToXxLargeFn: BreakpointFn = (bp) => bp === 'xl';

export const isXxLargeAndUpFn: BreakpointFn = (bp) => bp === '2xl';
export const isXLargeAndUpFn: BreakpointFn = (bp) =>
  isXLargeToXxLargeFn(bp) || isXxLargeAndUpFn(bp);
export const isLargeAndUpFn: BreakpointFn = (bp) =>
  isLargeToXLargeFn(bp) || isXLargeAndUpFn(bp);
export const isMdAndUpFn: BreakpointFn = (bp) =>
  isMdToLargeFn(bp) || isLargeAndUpFn(bp);
export const isSmAndUpFn: BreakpointFn = (bp) =>
  isSmToMdFn(bp) || isMdAndUpFn(bp);
export const isXxsAndUpFn: BreakpointFn = (bp) =>
  isXxsToSmFn(bp) || isSmAndUpFn(bp);
export const isBaseToMdFn: BreakpointFn = (bp) =>
  isBaseToSmFn(bp) || isSmToMdFn(bp);

export const isBaseToTabletFn: BreakpointFn = (bp) =>
  isBaseToSmFn(bp) || isSmToMdFn(bp);
export const isDesktopAndUpFn: BreakpointFn = (bp) => isLargeAndUpFn(bp);
