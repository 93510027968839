import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import {
  FOOTER_HEIGHT,
  MOBILE_NAV_HEIGHT,
  TOP_NAV_HEIGHT,
} from '@ui/config/ui';
import {
  isBaseToTabletFn,
  useBreakpointFn,
} from '@ui/hooks/chakra/useBreakpointFn';
import { SEOTemplate } from '@ui/SEO';
import { usePathname } from 'next/navigation';
import type { FC } from 'react';
import React from 'react';

import { Footer } from './Footer';
import MobileNavbar from './MobileNavbar';
import { TopNavbar } from './TopNavBar';

interface LayoutProps extends BoxProps {
  children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const pathname = usePathname();
  const isBaseToTablet = useBreakpointFn(isBaseToTabletFn);

  return (
    <Box
      backgroundImage={"url('/images/stake-page-bg.svg')"}
      backgroundSize="cover"
      bgRepeat="no-repeat"
      backgroundPosition="center"
      pb={{ base: `${MOBILE_NAV_HEIGHT}px`, lg: 0 }}
    >
      <SEOTemplate
        title="Capybara Nation"
        description="Build a futuristic city packed with cutting-edge web3 and AI upgrades, all while earning in-game coins to power up your quest cards and climb the ranks. It’s not just a game—it’s a global takeover led by Capybaras."
      />
      <TopNavbar />
      <Box
        minH={{
          base: 'auto',
          lg: `calc(100vh - ${TOP_NAV_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
        }}
      >
        {children}
      </Box>
      <Footer backgroundColor={pathname === '/' ? '#080314' : 'unset'} />
      {isBaseToTablet && <MobileNavbar />}
    </Box>
  );
};

export default Layout;
