/* eslint-disable @ts-intl/ts-intl/no-dynamic-keys */
import { Box, Flex } from '@chakra-ui/react';
import MobileWalletButton from '@ui/components/Molecules/MobileWalletButton';
import { MOBILE_NAV_HEIGHT } from '@ui/config/ui';
import { NavItemUrls } from '@ui/config/urls';
import { dynamicTranslateText } from '@ui/utils/i18n';
import { useTranslations } from 'next-intl';
import React, { useRef } from 'react';

import { TopNavLink } from '../TopNavBar/TopNavLink';

const MobileNavbar = () => {
  const t = useTranslations();
  const navRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      w="full"
      h={`${MOBILE_NAV_HEIGHT}px`}
      left={0}
      right={0}
      bottom={0}
      position="fixed"
      ref={navRef}
    >
      <Box
        boxSize="full"
        position="relative"
        background="linear-gradient(180deg, #42246D 0%, #4F35A9 50%, #38CFFF 140%)"
        display={{ base: 'flex', md: 'none' }}
        zIndex={1}
        ref={navRef}
      >
        <Flex w="full">
          <Flex
            flexGrow={1}
            px={2}
            alignItems="center"
            justifyContent="space-evenly"
          >
            {NavItemUrls.map((item) => (
              <TopNavLink href={item.url} key={item.title}>
                {dynamicTranslateText(t, item.title)}
              </TopNavLink>
            ))}
          </Flex>
          <Box flexBasis="76px" position="relative" mr={{ base: 4, sm: '7%' }}>
            <MobileWalletButton />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default MobileNavbar;
