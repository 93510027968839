import {
  AspectRatio,
  Box,
  Center,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CapybaraIcon } from '@cronos-app/icons';
import { currentWallet } from '@cronos-app/wallet';
import { useAccountModal } from '@ui/components/Atoms/AccountModal/useAccountModal';
import { MobileWalletButtonTag } from '@ui/components/Atoms/MobileWalletButtonTag';
import { useWalletModal } from '@ui/components/Atoms/Wallet';
import { useSwitchNetwork } from '@ui/hooks/useSwitchNetwork';
import { ellipseCenter } from '@ui/utils/formatString';
import { useTranslations } from 'next-intl';
import { HiOutlineExclamationCircle as Exclamation } from 'react-icons/hi';

const MobileWalletButton = () => {
  const t = useTranslations();

  const account = currentWallet.useAccount();
  const isConnected = currentWallet.useIsConnected();
  const isWrongNetwork = currentWallet.useIsWrongNetwork();

  const openWalletModal = useWalletModal((s) => s.openModal);
  const openAccountModal = useAccountModal((s) => s.openModal);
  const { mutate: switchNetwork, isLoading: isSwitchingNetwork } =
    useSwitchNetwork();

  const handleConnect = () => {
    if (isConnected && isWrongNetwork) switchNetwork();
    else if (isConnected) openAccountModal();
    else openWalletModal();
  };

  if (process.env.IS_DISABLED_STAKING === 'true') {
    return null;
  }

  return (
    <VStack w="full" h="140%" position="absolute" bottom="0">
      <AspectRatio ratio={1} w="full">
        <Box position="relative">
          <Center position="absolute" boxSize="full" mt={1} zIndex={1}>
            <IconButton
              unstyled
              borderRadius="full"
              _icon={{ boxSize: 16 }}
              disabled={isSwitchingNetwork}
              onClick={handleConnect}
            >
              <CapybaraIcon />
            </IconButton>
          </Center>
          <Center position="absolute" boxSize="full" zIndex={0}>
            <Box
              boxSize="full"
              borderRadius="full"
              background="#42246D"
              clipPath="polygon(0 0, 100% 0%, 100% 50%, 0 50%);"
            />
          </Center>
        </Box>
      </AspectRatio>
      <Center mt={-2} mb={2}>
        {isConnected && isWrongNetwork ? (
          <Text
            color="orange"
            fontSize="sm"
            pt={0.5}
            lineHeight={1}
            textTransform="uppercase"
            textAlign="center"
          >
            {t('common.Wrong network')}
          </Text>
        ) : (
          <Text color="white" textTransform="uppercase">
            {isConnected && !!account
              ? ellipseCenter(account)
              : t('common.Connect')}
          </Text>
        )}
      </Center>
      {isConnected && isWrongNetwork && (
        <MobileWalletButtonTag
          bgColor="white"
          borderRadius="full"
          iconProps={{ color: 'cOrange.500' }}
        >
          <Exclamation />
        </MobileWalletButtonTag>
      )}
    </VStack>
  );
};

export default MobileWalletButton;
