import { Button, HStack, Icon, Text, useToken } from '@chakra-ui/react';
import { CapybaraIcon } from '@cronos-app/icons';
import { currentWallet } from '@cronos-app/wallet';
import { useAccountModal } from '@ui/components/Atoms/AccountModal/useAccountModal';
import { useWalletModal } from '@ui/components/Atoms/Wallet';
import {
  isBaseToTabletFn,
  useBreakpointFn,
} from '@ui/hooks/chakra/useBreakpointFn';
import { useSwitchNetwork } from '@ui/hooks/useSwitchNetwork';
import { ellipseCenter } from '@ui/utils/formatString';
import { useTranslations } from 'next-intl';

export const WalletNavItem = () => {
  const t = useTranslations();
  // eslint-disable-next-line @ts-intl/ts-intl/no-raw-text
  const [cOrange300] = useToken('colors', ['cOrange.300']);
  const isBaseToTablet = useBreakpointFn(isBaseToTabletFn);

  const account = currentWallet.useAccount();
  const isConnected = currentWallet.useIsConnected();
  const isWrongNetwork = currentWallet.useIsWrongNetwork();

  const openWalletModal = useWalletModal((s) => s.openModal);
  const openAccountModal = useAccountModal((s) => s.openModal);
  const { mutate: switchNetwork, isLoading: isSwitchingNetwork } =
    useSwitchNetwork();

  if (process.env.IS_DISABLED_STAKING === 'true') {
    return null;
  }

  if (isBaseToTablet && isConnected) return null;

  if (isWrongNetwork) {
    return (
      <Button
        boxShadow={`0px 0px 14px 0px ${cOrange300}`}
        _hover={{ boxShadow: `0px 0px 20px 0px ${cOrange300}` }}
        disabled={isSwitchingNetwork}
        onClick={() => switchNetwork()}
      >
        <Text textStyle="lg" color="cOrange.300">
          {t('common.Wrong network')}
        </Text>
      </Button>
    );
  }

  if (isConnected) {
    return (
      <Button unstyled cursor="pointer" onClick={openAccountModal}>
        <HStack
          pl={3}
          pr={2}
          py={1}
          gap={2}
          borderRadius={8}
          alignItems="center"
          background="linear-gradient(102deg, #9226FF 4.81%, #38CFFF 92.55%);"
        >
          <Text color="white">{account && ellipseCenter(account)}</Text>
          <Icon boxSize={10} color="white">
            <CapybaraIcon />
          </Icon>
        </HStack>
      </Button>
    );
  }

  return <Button onClick={openWalletModal}>{t('common.Connect')}</Button>;
};
