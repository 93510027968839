import {
  currentWallet,
  getAddEthereumChainParameter,
} from '@cronos-app/wallet';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { toaster } from '@ui/components/Atoms/Toast';
import { useTranslations } from 'next-intl';

export const useSwitchNetwork = (options: UseMutationOptions = {}) => {
  const t = useTranslations();
  const isConnected = currentWallet.useIsConnected();

  return useMutation(async () => {
    const networkConfig = getAddEthereumChainParameter(
      Number(process.env.CHAIN_ID),
    );
    if (!isConnected) return;

    try {
      await currentWallet.connect(networkConfig);
    } catch (e: unknown) {
      toaster.create({
        type: 'error',
        description: t('common.Failed to switch network'),
      });
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      console.error(e as Error);
    }
  }, options);
};
