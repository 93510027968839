import { Center, Flex, Icon, Link } from '@chakra-ui/react';
import { Telegram, Tiktok, X } from '@cronos-app/icons';
import type { FC } from 'react';

interface SocialIconsProps {
  mode?: 'footer' | 'section';
}

const SocialIcons: FC<SocialIconsProps> = ({ mode }) => {
  // TODO: Replace with actual social URLs
  const socialUrls = [
    {
      href: 'https://www.tiktok.com/@capy.nation',
      Icon: Tiktok,
    },
    {
      href: 'https://t.me/capybaranation',
      Icon: Telegram,
    },
    {
      href: 'https://x.com/capy_nation',
      Icon: X,
    },
  ];

  if (mode === 'section') {
    return (
      <Flex gap={{ base: 8, lg: 10 }}>
        {socialUrls.map((socialUrl) => (
          <Link href={socialUrl.href} key={socialUrl.href} target="_blank">
            <Center
              boxSize={{ base: 12, lg: 16 }}
              background="white"
              borderRadius="full"
            >
              <Icon boxSize={{ base: 6, lg: 8 }} color="#42246D">
                <socialUrl.Icon />
              </Icon>
            </Center>
          </Link>
        ))}
      </Flex>
    );
  }

  return (
    <Flex gapX={{ base: 8, lg: 10 }} alignItems="center">
      {socialUrls.map((socialUrl) => (
        <Link href={socialUrl.href} key={socialUrl.href} target="_blank">
          <Icon boxSize="24px" color="#B4B4B4" paddingBottom="0px">
            <socialUrl.Icon />
          </Icon>
        </Link>
      ))}
    </Flex>
  );
};

export default SocialIcons;
