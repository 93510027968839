/* eslint-disable @ts-intl/ts-intl/no-dynamic-keys */
import { Box, Flex, HStack, Show } from '@chakra-ui/react';
import { NextImage } from '@ui/components/Atoms/NextImage';
import { NavItemUrls } from '@ui/config/urls';
import {
  isDesktopAndUpFn,
  useBreakpointFn,
} from '@ui/hooks/chakra/useBreakpointFn';
import { dynamicTranslateText } from '@ui/utils/i18n';
import { throttle } from 'lodash-es';
import NextLink from 'next/link';
import { useTranslations } from 'next-intl';
import React, { useEffect, useRef, useState } from 'react';
import { useMount } from 'react-use';

import { TopNavLink } from './TopNavLink';
import { WalletNavItem } from './WalletNavItem';

export const TopNavbar = () => {
  const t = useTranslations();
  const isDesktopAndUp = useBreakpointFn(isDesktopAndUpFn);

  const navRef = useRef<HTMLDivElement>(null);
  const [showSolidBackground, setShowSolidBackground] = useState(false);

  const handleScrollNav = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    const isTopOfPage = scrollTop < (navRef.current?.clientHeight ?? 0) / 2;
    // Always show the menu when user reach the top
    if (isTopOfPage) {
      setShowSolidBackground(false);
    } else {
      setShowSolidBackground(true);
    }
  };

  const handleScroll = () => {
    requestAnimationFrame(() => {
      handleScrollNav();
    });
  };

  const throttledHandleScroll = throttle(handleScroll, 200);

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll);
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
  }, [throttledHandleScroll]);

  useMount(() => {
    handleScrollNav();
  });

  return (
    <Flex
      position="sticky"
      top="0"
      left="0"
      right="0"
      zIndex="docked"
      align="center"
      justifyContent="center"
      pt={{ base: 8, lg: 6 }}
      pb={{ base: 4, lg: 6 }}
      px={{ base: 3, lg: 14 }}
      ref={navRef}
    >
      <HStack flex="1 1 auto" h="full">
        <NextLink href="/">
          <HStack>
            <NextImage
              width={{ base: '120px', lg: '145px' }}
              alt="Capybara Nation Logo"
              src="/images/nav-icon.svg"
              imageProps={{ priority: true }}
            />
          </HStack>
        </NextLink>
        <Show when={isDesktopAndUp}>
          <HStack
            gap={{ base: '8px', lg: '48px' }}
            ml={{ lg: '80px' }}
            display={{ base: 'none', lg: 'flex' }}
          >
            {NavItemUrls.map((item) => (
              <TopNavLink href={item.url} key={item.title}>
                {dynamicTranslateText(t, item.title)}
              </TopNavLink>
            ))}
          </HStack>
        </Show>

        <HStack ml="auto" h="full">
          <WalletNavItem />
        </HStack>
      </HStack>

      <Show when={showSolidBackground}>
        <Box
          boxSize="full"
          position="absolute"
          top="0"
          left="0"
          background="linear-gradient(to bottom, #080314, #2A0663)"
          animationName="fade-in"
          animationDuration="0.2s"
          animationTimingFunction="ease-in-out"
          zIndex={-1}
        />
      </Show>
    </Flex>
  );
};
